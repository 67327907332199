import React from "react";
import '../styles/style.css'
import FrameGameHeader from "./item/FrameGameHeader";

const EventGameHeader = () => {
    return (
        <div>
            <FrameGameHeader />
        </div>
    )
}

export default EventGameHeader;